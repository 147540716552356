<template>
	<div class="notice-wrap">
		<div><img src="../../assets/images/gongchang/banner.jpg" /></div>
		<div class="dsj_center">
			<div>
				<h2>外联采工业大数据中心</h2>
				<video autoplay="autoplay" height="360" loop="loop" poster="https://www.jswlc.com.cn/static/theme/zxy/dsj/video.jpg" src="https://www.jswlc.com.cn/static/theme/zxy/dsj/video.mp4" width="610">&nbsp;</video>
			</div>
			<div style="padding-left:20px;"><img src="../../assets/images/gongchang/video_text.jpg" /></div>
		</div>
		<div style="width:100%;text-align:center;padding-top:70px;padding-bottom:70px;">
			<div style="width:1000px;text-align:left;font-size:32px;margin:auto;padding-bottom:30px;">产品能力与服务</div>
			
			<div class="cpfw_content">
				<a class="cpfw_item">
					<div class="container">
						<img src="https://www.jswlc.com.cn/static/theme/zxy/dsj/cpfw_icon1.jpg">
						<div>
						<h3>丰富的算法组件</h3>
						涵盖多种经典机器学习算法组件和工业大数据算法组件</div>
					</div>
				</a>
				
				<a class="cpfw_item">
					<div class="container">
						<img src="https://www.jswlc.com.cn/static/theme/zxy/dsj/cpfw_icon2.jpg">
						<div>
						<h3>可视化开发体验</h3>
						无需开发代码，通过托拉拽形式快速搭建机器学习试验</div>
					</div>
				</a>
				
				<a class="cpfw_item">
					<div class="container">
						<img src="https://www.jswlc.com.cn/static/theme/zxy/dsj/cpfw_icon3.jpg">
						<div>
						<h3>自动化流程控制</h3>
						拖拽生成算法组件运行依赖关系及工作流，自动化执行</div>
					</div>
				</a>

				<a class="cpfw_item">
					<div class="container">
						<img src="https://www.jswlc.com.cn/static/theme/zxy/dsj/cpfw_icon4.jpg">
						<div>
						<h3>支持自定义算法</h3>
						支持Python,C/C++等多种语言编写的自定义算法包</div>
					</div>
				</a>
			</div>
		</div>
		<div style="width:100%;text-align:center;background:#1F253F;padding-top:30px;padding-bottom:50px;">
			<div style="width:1000px;text-align:left;font-size:32px;color:white;margin:auto;padding-bottom:20px;">平台优势</div>
			
			<div style="display:block;width:980px;height:390px;position:relative;margin:auto;">
				<img id="ptys_img" width="980" height="390" :src="'https://www.jswlc.com.cn/static/theme/zxy/dsj/ptys-'+currentItem+'.jpg'">
				<div id="ptys_nav" style="position:absolute;bottom:0px;right:0px;width:492px;height:56px;">
					<a class="currentItem==1?'current':''" @mouseover="currentItem=1">平台特点</a> <a class="currentItem==2?'current':''" @mouseover="currentItem=2">功能强大</a> <a :class="currentItem==3?'current':''" @mouseover="currentItem=3">海量数据处理</a>
				</div>
			</div>
		</div>
		<div><img src="../../assets/images/gongchang/scene.jpg" /></div>
		<div class="pop_button">
			<img src="../../assets/images/oversea/button.jpg" @click="dialogTableVisible=true" />
			<div class="line"></div>
		</div>
		<el-dialog title="意见反馈" width="600px" :visible.sync="dialogTableVisible" center>
			<el-form ref="form" :model="form">
		   <div class="form_item">企业名称：</div>
		   <el-input v-model="form.company_name" placeholder="请输入内容"></el-input>
		   <div class="form_item">所属行业：</div>
		   <el-input v-model="form.area" placeholder="请输入内容"></el-input>
		   <div class="form_item">想要咨询的服务：</div>
		   <el-select v-model="form.service" placeholder="请选择" style="width:100%;">
		       <el-option
		         v-for="item in service_options"
		         :key="item"
		         :label="item"
		         :value="item">
		       </el-option>
		     </el-select>
		   <div class="form_item">联系电话：</div>
		   <el-input v-model="form.contact" placeholder="请输入内容"></el-input>
		   <div class="form_item">备注：</div>
		   <el-input
		     type="textarea"
		     :rows="4"
		     placeholder="请输入内容"
		     v-model="form.memo">
		   </el-input>
			<div style="padding-top:20px;text-align: center;">
		       <el-button type="primary" @click="submitForm('form')">确 定</el-button>
			</div>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import http from "../../utils/http"

/**
 * 提交留言反馈
 */
export function submit_feedback(params) {
    return http({
        url: "/api/purchase/feedback",
        data: params
    })
}

export default {
  name: "list",
  data: () => {
    return {
		purchaseList:[],
		total: 0,
		currentPage: 1,
		pageCount:1,
		pageSize: 12,
		loading:true,
		currentItem:3,
		dialogTableVisible:false,
		centerDialogVisible:true,
		filters: {
		    order: "id",
		    sort: "desc"
		},
		service_options:[
			'国际物流海外仓储',
			'出口保理',
			'国际法务',
			'质量检测',
			'知识产权',
			'招标服务培训',
			'报关结汇退税',
			'供应链金融'
		],
		form:{
			company_name:'',
			area:'',
			service:'',
			contact:'',
			memo:''
		}
	};
  },
  methods: {
	submitForm(formName) {
		if(this.form.company_name==''){
			alert('请输入公司名称！');
			return false;
		}
		if(this.form.area==''){
			alert('请输入所属行业！');
			return false;
		}
		if(this.form.service==''){
			alert('请输入想要咨询的服务！');
			return false;
		}
		if(this.form.contact==''){
			alert('请输入联系电话！');
			return false;
		}
		if(this.form.memo==''){
			alert('请输入备注！');
			return false;
		}
		submit_feedback(this.form)
		.then(res => {
		    if (res.code == 0) {
				this.dialogTableVisible=false
				this.$alert('我们将在3个工作日内，\n提供一对一专属咨询服务，请您耐心等待！', '感觉您支持外联采！', {
					confirmButtonText: '确定',
					callback: action => {
						
					}
				});
		    }
		})
		
	}
  }
};
</script>
<style lang="scss" scoped>
.form_item{font-size:16px;padding-top:10px;}
.notice-wrap {
	margin-top:10px;
	padding-bottom:30px;
	background: #ffffff;
	overflow:hidden;
}
.dsj_center{background-color:#EEEEEE;display:flex;justify-content: center;text-align: center;padding-top:50px;padding-bottom:50px;}
.dsj_center h2{text-align: center;font-size:40px;font-weight: normal;padding-bottom:20px;}
.cpfw_content{display:flex;flex-direction: row;flex-wrap: wrap;justify-content: center;}
.cpfw_item{width:520px;text-align: left;display:flex;padding-bottom:20px;}
.cpfw_item div.container{width:500px;display:flex;padding:15px;border:2px solid #fff;}
.cpfw_item:hover{color: #1748FD;}
.cpfw_item:hover div.container{border:2px solid #1748FD;}
/*.cpfw_item a:hover img{display:none;}*/
.cpfw_item img{margin-right:20px;}

#ptys_nav{display:flex;justify-content:space-around;}
#ptys_nav a{width:164px;text-align:center;line-height:60px;border-right:1px solid #D6D7D6;border-top:1px solid #D6D7D6;cursor:pointer}
#ptys_nav a:hover,
#ptys_nav a.current{background:#1748FD;color:white;}

.pop_button{text-align: center;padding-top:20px;position:relative;z-index:100;background:#F6F6F6;}
.pop_button div.line{width:100%;height:3px;background:#666;position:absolute;bottom:22px;z-index:-1;}
</style>
